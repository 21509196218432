import { React, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import MultipleSelectField from 'common/forms/MultipleSelectField';
import DateRangePickerField from 'common/forms/DateRangePickerField';
import { updateRxIntakeRequestFilter } from 'store/thunks/workQueueThunks';
import { rxIntakeRequestStatus } from 'enums/request.enum';
import { keys } from 'lodash';

const RxInTakeRequestsOuterFilter = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue: { rxIntakeRequests } }) => rxIntakeRequests.filter);
  const [selectedStatus, setSelectedStatus] = useState('ALL');

  useEffect(() => {
    if (!filter.requestStatus) {
      setSelectedStatus('ALL');
    } else {
      setSelectedStatus(filter.requestStatus);
    }
  }, [filter]);

  const onHandleDateChange = (dateRange) => {
    dispatch(
      updateRxIntakeRequestFilter({
        filter: {
          ...filter,
          createdAt: dateRange,
        },
      })
    );
  };

  const onHandleStatusChange = (value) => {
    setSelectedStatus(value);
    dispatch(
      updateRxIntakeRequestFilter({
        filter: {
          ...filter,
          requestStatus: value === 'ALL' ? undefined : value,
        },
      })
    );
  };

  return (
    <>
      <Grid item>
        <MultipleSelectField
          id='RxInTakeRequestStatus-Select'
          name='status'
          options={keys(rxIntakeRequestStatus)}
          label='Status'
          selectedValue={selectedStatus}
          onChange={onHandleStatusChange}
        />
      </Grid>
      <Grid item>
        <DateRangePickerField
          label='Date Range'
          onDateChange={onHandleDateChange}
          initialValue={filter?.createdAt}
        />
      </Grid>
    </>
  );
};

export default RxInTakeRequestsOuterFilter;
