import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { keys, map } from 'lodash';
import useInfoDialog from 'common/hooks/useInfoDialog';
import { cancelOrder } from 'store/thunks/patientThunks';
import { enqueueSnackbar } from 'notistack';
import SelectField from 'common/forms/SelectField';
import cancelOrderReasons from 'common/constants/cancelOrderReasons';
import MarkdownField from 'common/forms/MarkdownField';
import { validateMaxLength } from 'common/forms/formValidations';
import { filterTransactions } from 'store/thunks/transactionsThunk';
import useRoles from 'common/hooks/useRoles';

const CancelOrderButton = ({ orderId, mpi, transactionId, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const dispatch = useDispatch();
  const hasAccess = useRoles();

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleCancelOrder = ({ cancelReason, note }) => {
    showInfoDialog({
      title: 'Confirm Cancel',
      message: `Are you sure you want to cancel this order, ${orderId}?`,
      callback: () => {
        setIsLoading(true);
        dispatch(cancelOrder({ mpi, orderId, cancelReason, note })).then((isCanceled) => {
          setIsLoading(false);
          if (isCanceled) {
            enqueueSnackbar(`Order ${orderId} Canceled`, { variant: 'warning' });
            setShowModal(false);
          }

          /* This is for cancelling the order from the transaction list */
          if (transactionId) {
            dispatch(filterTransactions({ filter: { transactionType: 'FAILED' } }));
          }
        });
      },
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Yes',
    });
  };

  return (
    <>
      <InfoDialog />

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='CancelOrder-Dialog-header'
        fullWidth
      >
        <DialogTitle id='CancelOrder-header'>{`Cancel Order ${orderId}`}</DialogTitle>

        <Form
          onSubmit={handleCancelOrder}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <SelectField
                  id='CancelOrder-cancelReason-dropdown'
                  label='Reason for canceling order'
                  name='cancelReason'
                  required
                >
                  {map(keys(cancelOrderReasons), (key) => (
                    <MenuItem value={key} key={key}>
                      {cancelOrderReasons[key]}
                    </MenuItem>
                  ))}
                </SelectField>

                <MarkdownField
                  name='note'
                  id='CancelOrder-cancelNote-input'
                  validations={[
                    validateMaxLength('The note is too long. Please make it shorter', 65435),
                  ]}
                  label='Cancel Note'
                  required
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isLoading}
                  variant='contained'
                  type='submit'
                  disabled={invalid}
                >
                  Submit
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>

      <Grid>
        <LoadingButton
          variant='outlined'
          color='error'
          onClick={handleOpenModal}
          disabled={!hasAccess.cancelOrder || disabled}
        >
          Cancel
        </LoadingButton>
      </Grid>
    </>
  );
};

CancelOrderButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  transactionId: PropTypes.string,
  disabled: PropTypes.bool,
};

CancelOrderButton.defaultProps = {
  transactionId: undefined,
  disabled: false,
};

export default CancelOrderButton;
