import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import WorkQueues from 'enums/workQueue.enum';

import RxInTakeRequestsTable from './RxInTakeRequestsTable';
import RxInTakeRequestsFilterButton from './RxInTakeRequestsFilterButton';
import RxInTakeRequestsFilterStatusBar from './RxInTakeRequestsFilterStatusBar';
import RxInTakeRequestsOuterFilter from './RxInTakeRequestsOuterFilter';

const RxInTakeRequests = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.RX_INTAKE_REQUESTS) return null;

  return (
    <Grid container sx={{ py: 3 }} justifyContent='space-between' alignItems='center'>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }}>
        <RxInTakeRequestsFilterStatusBar />
      </Grid>
      <Grid item>
        <Grid container gap={2}>
          <RxInTakeRequestsOuterFilter />
          <Grid item sx={{ mt: 3 }}>
            <RxInTakeRequestsFilterButton />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <RxInTakeRequestsTable />
      </Grid>
    </Grid>
  );
};

export default RxInTakeRequests;
