import { getFulfillingPharmaciesApi, transferOrderToFacilityApi } from 'api/pharmacy';
import { pharmacyActions } from 'store/slices/pharmacySlice';
import { handleError } from './errorHandlerThunks';
import { listPatientOrders } from './patientThunks';

export const getFulfillingPharmacies = () => (dispatch, getState) => {
  const { currentTenantId } = getState().auth;

  return getFulfillingPharmaciesApi({ pharmacyId: currentTenantId })
    .then((fulfillingPharmacies) =>
      dispatch(
        pharmacyActions.loadPharmacyData({ fulfillingPharmacies, tenantId: currentTenantId })
      )
    )
    .catch((error) => dispatch(handleError({ error })));
};

export const transferOrderToFacility =
  ({ orderId, toFacilityId, mpi }) =>
  (dispatch) =>
    transferOrderToFacilityApi({ orderId, toFacilityId })
      .then((isTransferred) => {
        dispatch(listPatientOrders({ mpi }));

        return isTransferred;
      })
      .catch((error) => dispatch(handleError({ error })));
