import { format } from 'date-fns';
import PropTypes, { string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import MultiDatePicker from 'react-multi-date-picker';
import { FormHelperText, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { includes, isEmpty, isEqual } from 'lodash';

const StyledDatePicker = styled(MultiDatePicker)(({ theme }) => ({
  '& .rmdp-week-day': {
    color: theme.palette.primary.main,
    cursor: 'default',
    fontSize: '13px',
    fontWeight: 500,
  },
  '& .rmdp-input:focus': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& .rmdp-range': {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 0 3px #fff',
    color: '#fff',
  },
  '& .rmdp-range-hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .rmdp-day.rmdp-today span': {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    borderColor: '#fff',
  },
  '& .rmdp-day:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover': {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  '& .rmdp-arrow-container:hover': {
    backgroundColor: theme.palette.primary.light,
    boxShadow: '0 0 3px #8798ad',
  },
  '& .rmdp-arrow': {
    border: `solid ${theme.palette.primary.light}`,
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    height: '3px',
    marginTop: '5px',
    padding: '3px',
    width: '3px',
  },
}));

const DateRangePickerField = ({ label, initialValue, onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState([]);
  const [isValidDate, setIsValidDate] = useState(true);
  const [prevDateRange, setPrevDateRange] = useState(null);
  const displayedHelperText = !isValidDate ? 'Please select a valid date' : '';

  useEffect(() => {
    if (!initialValue) {
      setSelectedDate([]);
    }
  }, [initialValue]);

  const onHandleDateChange = (data) => {
    setSelectedDate(data);
    const dates = data?.map((sDate) => sDate.toDate?.().toString());

    if (!includes(dates, 'Invalid Date')) {
      setIsValidDate(true);
      const dateRange = dates?.map((date) => format(new Date(date), 'yyyy-MM-dd'));
      setPrevDateRange(dateRange);

      const startDate = !isEmpty(dateRange) && dateRange[0];
      const endDate = !isEmpty(dateRange) && dateRange[1];

      if (!isEqual(dateRange, prevDateRange) && !isEqual(startDate, endDate)) {
        onDateChange(dateRange);
      }
    } else {
      setIsValidDate(false);
    }
  };

  return (
    <>
      <InputLabel shrink id='' sx={{ fontSize: '1.2em' }}>
        {label}
      </InputLabel>
      <StyledDatePicker
        name='dateRange'
        placeholder='MM-DD-YYYY ~ MM-DD-YYYY'
        format='MM-DD-YYYY'
        style={{
          width: '100%',
          minWidth: 210,
          height: '35px',
          padding: 10,
          margin: 0,
          borderColor: !isValidDate && 'red',
        }}
        calendarPosition='bottom-center'
        range
        rangeHover
        value={selectedDate}
        onChange={onHandleDateChange}
      />
      <FormHelperText sx={{ color: 'red', pl: 1 }}>{displayedHelperText}</FormHelperText>
    </>
  );
};

export default DateRangePickerField;

DateRangePickerField.propTypes = {
  initialValue: PropTypes.arrayOf(string),
  label: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

DateRangePickerField.defaultProps = {
  initialValue: undefined,
};
