import React from 'react';
import { Grid } from '@mui/material';

import PartnersTable from './components/PartnersTable';
import AddPartnerButton from './components/AddPartnerButton';
import PartnerFilterButton from './components/PartnerFilterButton';
import PartnerFilterStatusBar from './components/PartnerFilterStatusBar';

const PartnerManagement = () => {
  return (
    <>
      <Grid
        container
        sx={{ px: 3, py: 2 }}
        direction='row'
        justifyContent='space-between'
        alignItems='flex-end'
      >
        <Grid item sx={{ minHeight: 30, flexGrow: 1 }} alignItems='center'>
          <PartnerFilterStatusBar />
        </Grid>
        <Grid item alignItems='center'>
          <AddPartnerButton />
          <PartnerFilterButton />
        </Grid>
      </Grid>

      <Grid item container sx={{ px: 3 }}>
        <PartnersTable />
      </Grid>
    </>
  );
};

export default PartnerManagement;
