export default {
  line1: '1010 West Madison St',
  line2: 'Suite 1',
  city: 'Washington',
  state: 'IA',
  zip: '52353',
};

export const PartnerProfileFieldKeys = {
  PARTNER_NAME: 'partnerName',
  PARTNER_SHORT_CODE: 'partnerId',
  CREATED_AT: 'createdTimestamp',
  UPDATED_AT: 'modifiedTimestamp',
  HAS_API_ACCESS: 'hasApiAccess',
};

export const PartnerProfileApiAccess = {
  HAS_API_ACCESS: true,
  NO_API_ACCESS: false,
};
