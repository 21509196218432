import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { includes, isEmpty, isNil, map, toLower } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { OrderStatusFieldKeys } from 'enums/requestStatus.enum';
import { SortOrder } from 'enums/common.enum';
import {
  loadPrescriptionOrders,
  updatePrescriptionOrdersSortBy,
} from 'store/thunks/orderStatusThunks';

import EScriptOrdersOptions from './EScriptOrdersOptions';

const headCells = [
  {
    id: OrderStatusFieldKeys.REFERENCE_ID,
    label: 'Reference ID',
    align: 'left',
  },
  {
    id: OrderStatusFieldKeys.PATIENT_NAME,
    label: 'Patient Name',
    align: 'left',
  },
  {
    id: OrderStatusFieldKeys.PARTNER,
    label: 'Partner',
    align: 'left',
  },
  {
    id: OrderStatusFieldKeys.EXTERNAL_PATIENT_ID,
    label: 'External Patient Id',
    align: 'left',
  },
  {
    id: OrderStatusFieldKeys.ORDER_ID,
    label: 'Order Id',
    align: 'left',
  },
  {
    id: OrderStatusFieldKeys.CREATED_AT,
    label: 'Created At',
    align: 'left',
  },
  {
    id: OrderStatusFieldKeys.STATUS,
    label: 'Order Status',
    align: 'left',
  },
];

const sortableFields = [
  OrderStatusFieldKeys.REFERENCE_ID,
  OrderStatusFieldKeys.PATIENT_NAME,
  OrderStatusFieldKeys.EXTERNAL_PATIENT_ID,
  OrderStatusFieldKeys.ORDER_ID,
  OrderStatusFieldKeys.CREATED_AT,
];

const EScriptOrdersTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { sortBy, pages, currentPage, count } = useSelector(
    ({ orderStatus: { eScriptOrders } }) => eScriptOrders
  );
  const eScriptOrderList = pages[currentPage];
  const totalPages = Math.ceil(count / 25);

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      updatePrescriptionOrdersSortBy({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy.sortKey === sortKey && sortBy.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return;
    }

    dispatch(loadPrescriptionOrders({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(loadPrescriptionOrders({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid container direction='column' sx={{ py: 1 }}>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.align}>
                    {!includes(sortableFields, headCell.id) ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={sortBy?.sortKey === headCell.id}
                        direction={
                          sortBy?.sortKey === headCell.id ? toLower(sortBy?.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {map(
                eScriptOrderList,
                (
                  {
                    requestId,
                    referenceId,
                    patientName,
                    partnerName,
                    externalPatientId,
                    pomOrderId,
                    createdAt,
                    status,
                    patient,
                  },
                  index
                ) => (
                  <TableRow key={`${index}${referenceId}`} hover>
                    <TableCell align='left'>{referenceId}</TableCell>
                    <TableCell align='left'>
                      <Link
                        to={`/patients/${patient.mpi}`}
                        style={{ color: theme.palette.text.primary }}
                      >
                        {patientName}
                      </Link>
                    </TableCell>
                    <TableCell align='left'>{partnerName} </TableCell>
                    <TableCell
                      align='left'
                      sx={{
                        maxWidth: 250,
                        overflow: 'hidden',
                      }}
                    >
                      <Tooltip title={externalPatientId}>
                        <Typography
                          noWrap
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            maxWidth: '100%',
                          }}
                        >
                          {externalPatientId}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align='left'>{pomOrderId || 'N/A'} </TableCell>
                    <TableCell align='left'>{format(new Date(createdAt), 'MM/dd/yyyy')}</TableCell>
                    <TableCell align='left'>{status}</TableCell>
                    <TableCell align='left'>
                      <EScriptOrdersOptions referenceId={referenceId} requestId={requestId} />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>

            {!isNil(eScriptOrderList) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={9} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(eScriptOrderList) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(eScriptOrderList) && isEmpty(eScriptOrderList) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default EScriptOrdersTable;
