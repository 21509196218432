/* eslint-disable import/prefer-default-export */
import graphqlClient from '../graphql';
import { getFulfillingPharmaciesGql, transferOrderToFacilityGql } from './gql';

export const getFulfillingPharmaciesApi = ({ pharmacyId }) =>
  graphqlClient
    .request(getFulfillingPharmaciesGql, { pharmacyId })
    .then(({ getFulfillingPharmacies }) => getFulfillingPharmacies);

export const transferOrderToFacilityApi = ({ orderId, toFacilityId }) =>
  graphqlClient
    .request(transferOrderToFacilityGql, { orderId, toFacilityId })
    .then(({ transferOrderFulfillingPharmacy }) => transferOrderFulfillingPharmacy);
