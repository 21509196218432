/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { isEmpty } from 'lodash';
import { CircularProgress, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import TextField from 'common/forms/TextField';
import {
  validateMaxLength,
  validateMinLength,
  validateValueWithRegex,
} from 'common/forms/formValidations';
import AutoCompleteMultipleSelect from 'common/forms/AutoCompleteMultipleSelect';

const PartnerProfileForm = ({ fulfillingPharmacyNames, fulfillingPharmacyLoading }) => {
  return (
    <>
      <TextField
        id='Create-Partner-Profile-PartnerName-input'
        label='Partner Name'
        name='partnerName'
        required
        autoFocus
        validations={[validateMinLength('Partner Name must be at least 3 characters', 3)]}
      />
      <TextField
        id='Create-Partner-Profile-PartnerShortCode-input'
        label='Partner Short Code'
        name='partnerId'
        required
        validations={[
          validateMinLength('Partner Short Code must be at least 2 characters', 2),
          validateMaxLength('Partner Short Code cannot exceed 5 characters', 5),
          validateValueWithRegex(
            'Partner Short Code should not contain special characters.',
            /^[a-zA-Z0-9]+$/
          ),
        ]}
      />
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item sx={{ width: fulfillingPharmacyLoading ? '90%' : '100%' }}>
          <AutoCompleteMultipleSelect
            name='fulfillingPharmacyIds'
            label='Fulfilling Pharmacies'
            id='Create-Partner-Profile-FullFillPharmacy-SelectField'
            options={fulfillingPharmacyNames}
            disabled={fulfillingPharmacyLoading || isEmpty(fulfillingPharmacyNames)}
          />
        </Grid>
        {fulfillingPharmacyLoading && (
          <Grid item>
            <CircularProgress size={30} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PartnerProfileForm;

PartnerProfileForm.propTypes = {
  fulfillingPharmacyNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  fulfillingPharmacyLoading: PropTypes.bool.isRequired,
};
