import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, map, omit, values } from 'lodash';

import { SortKey } from 'enums/common.enum';
import { rxIntakeRequestStatus } from 'enums/request.enum';
import { updateRxIntakeRequestFilter } from 'store/thunks/workQueueThunks';

const RxInTakeRequestsFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.rxIntakeRequests?.filter);

  const removeAllFilter = () =>
    dispatch(
      updateRxIntakeRequestFilter({
        filter: {},
      })
    );

  const removeFilter = (filterName) => () => {
    dispatch(
      updateRxIntakeRequestFilter({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const getDateRange = (dateRange) => {
    const dates = map(dateRange, (date) => format(new Date(date), 'MM/dd/yyyy'));

    return dates.length === 1 ? `${dates[0]}` : `${dates[0]} - ${dates[1]}`;
  };

  return (
    <Stack direction='row' spacing={1}>
      {filter.patientName && (
        <Chip
          label={`Name = ${filter.patientName}`}
          onDelete={removeFilter(SortKey.PATIENT_NAME)}
        />
      )}

      {filter.medicationName && (
        <Chip
          label={`Medication = ${filter.medicationName}`}
          onDelete={removeFilter(SortKey.MEDICATION_NAME)}
        />
      )}

      {filter.requestStatus && (
        <Chip
          label={`Status = ${rxIntakeRequestStatus[filter.requestStatus]}`}
          onDelete={removeFilter(SortKey.REQUEST_STATUS)}
        />
      )}

      {filter.practiceName && (
        <Chip
          label={`Practice = ${filter.practiceName}`}
          onDelete={removeFilter(SortKey.PRACTICE_NAME)}
        />
      )}
      {filter.scriptId && (
        <Chip
          label={`Docutrack Id = ${filter.scriptId}`}
          onDelete={removeFilter(SortKey.SCRIPT_ID)}
        />
      )}

      {filter?.createdAt && (
        <Chip
          label={`Date Range = ${getDateRange(filter.createdAt)}`}
          variant='contained'
          onDelete={removeFilter(SortKey.CREATED_AT)}
        />
      )}

      {!isEmpty(compact(values(omit(filter, 'taggedForReview')))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default RxInTakeRequestsFilterStatusBar;
