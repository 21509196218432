import React from 'react';
import { Button, Chip, Stack } from '@mui/material';
import { compact, isBoolean, isEmpty, values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { PartnerProfileFieldKeys } from 'enums/partner.enum';
import { updatePartnerProfileFilter } from 'store/thunks/partnerThunks';

const PartnerFilterStatusBar = () => {
  const dispatch = useDispatch();

  const formatApiAccessStatus = (hasApiAccess) => {
    if (isBoolean(hasApiAccess)) {
      return hasApiAccess ? 'Yes' : 'No';
    }
    return undefined;
  };

  const partnerFilter = useSelector(({ partner: { partnerManagement } }) => ({
    ...partnerManagement.filter,
    hasApiAccess: formatApiAccessStatus(partnerManagement.filter?.hasApiAccess),
  }));

  const removeFilter = (filterName) => () => {
    dispatch(
      updatePartnerProfileFilter({
        filter: {
          ...partnerFilter,
          hasApiAccess:
            filterName !== PartnerProfileFieldKeys.HAS_API_ACCESS &&
            partnerFilter?.hasApiAccess &&
            partnerFilter.hasApiAccess === 'Yes',
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(updatePartnerProfileFilter({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {partnerFilter?.partnerName && (
        <Chip
          label={`Partner Name = ${partnerFilter.partnerName}`}
          onDelete={removeFilter(PartnerProfileFieldKeys.PARTNER_NAME)}
        />
      )}
      {partnerFilter?.partnerShortCode && (
        <Chip
          label={`Partner Short Code = ${partnerFilter.partnerShortCode}`}
          onDelete={removeFilter('partnerShortCode')}
        />
      )}
      {partnerFilter?.hasApiAccess && (
        <Chip
          label={`Api Access = ${partnerFilter.hasApiAccess}`}
          onDelete={removeFilter(PartnerProfileFieldKeys.HAS_API_ACCESS)}
        />
      )}
      {!isEmpty(compact(values(partnerFilter))) && !isBoolean(compact(values(partnerFilter))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default PartnerFilterStatusBar;
