import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { VerificationStatus, VerificationStatusInfo } from 'enums/provider.enum';

const VerifiedTag = ({ status }) => {
  if (!status) {
    return null;
  }

  const statusIcon = {
    [VerificationStatus.VERIFIED]: <CheckCircleIcon color='success' sx={{ ml: 0.5 }} />,
    [VerificationStatus.UNVERIFIED]: <WarningIcon color='warning' sx={{ ml: 0.5 }} />,
    [VerificationStatus.FAILED]: <ErrorOutlineIcon color='error' sx={{ ml: 0.5 }} />,
  };

  return (
    <Tooltip title={VerificationStatusInfo[status]} placement='right'>
      {statusIcon[status]}
    </Tooltip>
  );
};

export default VerifiedTag;

VerifiedTag.propTypes = {
  status: PropTypes.string.isRequired,
};
