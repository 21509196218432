export default {
  PATIENT_QUALIFICATION: 'patientQualification',
  IN_CLAIM_ELIGIBILITY: 'inClaimEligibility',
  REVIEW_IN_CLAIM_ELIGIBILITY: 'reviewInClaimEligibility',
  ORDER_LINE_ITEMS: 'orderLineItems',
  ORDER_VERIFICATION: 'orderVerification',
  PRODUCT_FULFILLMENT: 'productFulfillment',
  BULK_LABELS: 'bulkLabels',
  READY_TO_SHIP: 'readyToShip',
  REVIEW_ADJUDICATION: 'reviewAdjudication',
  INCOMPLETE_PROFILES: 'profileIncomplete',
  PRESCRIPTION_REVIEW: 'prescriptionReview',
  E_VISIT_REQUESTS: 'eVisitRequests',
  RX_INTAKE_REQUESTS: 'rxIntakeRequests',
};
