import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const pharmacySlice = createSlice({
  name: 'pharmacy',
  initialState,
  reducers: {
    resetPharmacyState(state) {
      Object.assign(state, initialState);
    },
    loadPharmacyData(state, action) {
      const { fulfillingPharmacies, tenantId } = action.payload;

      Object.assign(state, { [tenantId]: fulfillingPharmacies });
    },
  },
});

export const pharmacyActions = pharmacySlice.actions;
export default pharmacySlice.reducer;
