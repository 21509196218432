import { createSlice } from '@reduxjs/toolkit';
import { assign, find, values } from 'lodash';

const initialState = {
  partnerManagement: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      partnerName: undefined,
      partnerShortCode: undefined,
      hasApiAccess: undefined,
    },
    sortBy: {
      sortKey: 'createdTimestamp',
      sortOrder: 'ASC',
    },
  },
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    resetPartnerState(state) {
      Object.assign(state, initialState);
    },
    loadPartners(state, action) {
      const { partners, currentTenantId } = action.payload;
      state[currentTenantId] = partners;
    },
    loadPartnerPractices(state, action) {
      const { currentTenantId, practices, partnerId } = action.payload;

      const partner = find(state[currentTenantId], { partnerUuid: partnerId });

      assign(partner, { ...partner, partnerPractices: practices });

      state[currentTenantId] = values(state[currentTenantId]);
    },

    listPartnerProfiles(state, action) {
      const { page, items, count } = action.payload;

      state.partnerManagement.count = count;
      state.partnerManagement.pages[page] = items;
    },

    updateCurrentPage(state, action) {
      const { page } = action.payload;

      state.partnerManagement.currentPage = page;
    },

    updateSortBy(state, action) {
      const { sortBy } = action.payload;

      state.partnerManagement.pages = {};
      state.partnerManagement.count = 0;
      state.partnerManagement.sortBy = sortBy;
    },

    updateFilter(state, action) {
      const { filter } = action.payload;

      state.partnerManagement.pages = {};
      state.partnerManagement.count = 0;
      state.partnerManagement.filter = filter;
    },
  },
});

export const partnerActions = partnerSlice.actions;
export default partnerSlice.reducer;
