import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { getPartnerWorkflowConfig } from 'store/thunks/partnerThunks';

import SwitchField from 'common/forms/SwitchField';
import { isEqual } from 'lodash';

const PartnerConfigModal = ({ showModal, loading, handleClose, partner, handleWorkflowConfig }) => {
  const dispatch = useDispatch();
  const [config, setConfig] = useState(null);
  const [configLoading, setConfigLoading] = useState(false);
  const initialConfigRef = useRef(null);
  const requestConfig = config?.requestInitConfig;
  const eScriptConfig = requestConfig?.TRANSMIT_E_SCRIPT;

  const fetchPartnerWorkflowConfig = useCallback(
    (partnerUUID) => {
      setConfigLoading(true);
      dispatch(getPartnerWorkflowConfig(partnerUUID))
        .then(({ workflowConfig }) => {
          initialConfigRef.current = workflowConfig.config;
          setConfig(workflowConfig.config);
        })
        .finally(() => setConfigLoading(false));
    },
    [dispatch]
  );

  const handleConfigChange = ({ key, value, requestType }) => {
    setConfig((prevConfig) => {
      if (requestType) {
        return {
          ...prevConfig,
          requestInitConfig: {
            ...prevConfig.requestInitConfig,
            [requestType]: {
              ...requestConfig[requestType],
              [key]: value,
              ...(requestType === 'TRANSMIT_E_SCRIPT' &&
                key === 'REQUEST_PROCESSING' && { ENRICH_BEFORE_EXTERNAL_PROCESSING: false }),
            },
          },
        };
      }

      return { ...config, [key]: value };
    });
  };

  useEffect(() => {
    fetchPartnerWorkflowConfig(partner.partnerUuid);
  }, [partner, fetchPartnerWorkflowConfig]);

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-describedby='Partner-workflow-management'
      fullWidth
    >
      <DialogTitle sx={{ p: 2, backgroundColor: 'primary.main' }}>
        {`Partner Workflow Config - ${partner.partnerName}`}
      </DialogTitle>

      <DialogContent sx={{ minWidth: 500, p: 3, mt: 3 }}>
        {configLoading ? (
          <Grid container sx={{ minHeight: 400 }} justifyContent='center' alignItems='center'>
            <CircularProgress color='primary' size={50} />
          </Grid>
        ) : (
          <>
            <Grid container spacing={2} sx={{ px: 4 }}>
              {[
                {
                  disabled: true,
                  defaultEnabled: true,
                  key: 'autoAuthorization',
                  label: 'Auto Authorization',
                },
                {
                  disabled: true,
                  defaultEnabled: true,
                  key: 'autoOnboardPatient',
                  label: 'Auto Onboard Patient',
                },
                {
                  disabled: false,
                  defaultEnabled: false,
                  key: 'allowZendeskIntegration',
                  label: 'Allow Zendesk Integration',
                },
              ].map(({ defaultEnabled, disabled, label, key }) => (
                <Grid item xs={12} key={label} container alignItems='center' sx={{ mt: 1 }}>
                  <Grid item xs>
                    <Typography variant='subtitle1'>{label}</Typography>
                  </Grid>
                  <Grid item>
                    <SwitchField
                      label={label}
                      disabled={disabled}
                      checked={config?.[key] || defaultEnabled}
                      onClick={() => handleConfigChange({ key, value: !config[key] })}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid container sx={{ mt: 5, mb: 1, px: 2 }}>
              <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                Request Config
              </Typography>
            </Grid>
            <Divider sx={{ mx: 2 }} />

            <Grid container sx={{ py: 2 }}>
              <Chip
                label='E-SCRIPT'
                color='primary'
                sx={{ fontWeight: 'bold', fontSize: '0.8rem', mx: 2, mt: 1, p: 0 }}
              />

              <Grid container sx={{ my: 1, px: 3 }}>
                <Grid item xs={12} container alignItems='center' sx={{ my: 2 }}>
                  <Grid item xs>
                    <Typography variant='subtitle1' sx={{ mb: 1 }}>
                      Request Processing
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Select
                      displayEmpty
                      size='small'
                      sx={{ minWidth: 125 }}
                      value={eScriptConfig?.REQUEST_PROCESSING || 'POM'}
                      onChange={(e) =>
                        handleConfigChange({
                          value: e.target.value,
                          key: 'REQUEST_PROCESSING',
                          requestType: 'TRANSMIT_E_SCRIPT',
                        })
                      }
                    >
                      <MenuItem value='POM'>POM</MenuItem>
                      <MenuItem value='ZENDESK'>ZENDESK</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          loading={loading}
          disabled={configLoading || loading || isEqual(config, initialConfigRef.current)}
          onClick={() => handleWorkflowConfig(config)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PartnerConfigModal;

PartnerConfigModal.propTypes = {
  partner: PropTypes.shape({
    partnerUuid: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
  }).isRequired,
  showModal: PropTypes.bool,
  loading: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleWorkflowConfig: PropTypes.func.isRequired,
};

PartnerConfigModal.defaultProps = {
  showModal: false,
  loading: false,
};
