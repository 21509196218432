import PropTypes from 'prop-types';
import { React, useState } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { savePartnerWorkflowConfig } from 'store/thunks/partnerThunks';

import PartnerConfigModal from './PartnerConfigModal';

const PartnerOptions = ({ partner }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showWorkflowModal, setShowWorkflowModal] = useState(false);
  const menuOpen = !!anchorEl;

  const openShowWorkflowModal = () => setShowWorkflowModal(true);

  const closeWorkflowConfigModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowWorkflowModal(false);
    }
  };

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handlePartnerWorkflowConfig = (configData) => {
    setLoading(true);

    dispatch(
      savePartnerWorkflowConfig({ partnerUUID: partner.partnerUuid, input: { config: configData } })
    )
      .then((response) => {
        if (response) {
          enqueueSnackbar('Partner workflow config updated successfully', {
            variant: 'success',
          });
          closeWorkflowConfigModal();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {showWorkflowModal && (
        <PartnerConfigModal
          partner={partner}
          loading={loading}
          showModal={showWorkflowModal}
          handleClose={closeWorkflowConfigModal}
          handleWorkflowConfig={handlePartnerWorkflowConfig}
        />
      )}

      <IconButton aria-label='PartnerManagement Options' onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClick={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={openShowWorkflowModal}>Edit</MenuItem>
      </Menu>
    </>
  );
};

export default PartnerOptions;

PartnerOptions.propTypes = {
  partner: PropTypes.shape({
    partnerUuid: PropTypes.string.isRequired,
    partnerName: PropTypes.string.isRequired,
  }).isRequired,
};
