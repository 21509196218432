/* eslint-disable max-lines */
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { find, isEmpty, map, omit } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { lighten, useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import gender from 'common/constants/gender';
import TextField from 'common/forms/TextField';
import DateField from 'common/forms/DateField';
import SelectField from 'common/forms/SelectField';
import CheckboxField from 'common/forms/CheckboxField';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { formatZipCode } from 'common/utils';
import { getPartnerPractices } from 'store/thunks/partnerThunks';
import {
  validateDateConstraint,
  validateEmail,
  validateMaxDate,
  validateMinDate,
  validateMinLength,
  validateMinNumber,
  validateValueWithRegex,
  validateZipCode,
} from 'common/forms/formValidations';
import LoadingModule from 'common/components/LoadingModule';

const RxInTakeRequestEditModal = ({
  showModal,
  onClose,
  loading,
  scriptId,
  handleImportEnrichedRequest,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [practices, setPractices] = useState(null);
  const [practiceLoading, setPracticeLoading] = useState(false);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [initialRequestPayload, setInitialRequestPayload] = useState(null);

  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);
  const { pages, currentPage } = useSelector(({ workQueue }) => workQueue.rxIntakeRequests);
  const scriptDetails = find(pages[currentPage], { scriptId });
  const enrichedPrescriptionPayload = scriptDetails?.requestPayload?.enrichedPrescriptionPayload;
  const { patient, prescription } = enrichedPrescriptionPayload;
  const { transferDetails } = prescription;

  const partner = useMemo(
    () => (selectedPartnerId ? find(partners, { partnerUuid: selectedPartnerId }) : null),
    [selectedPartnerId, partners]
  );

  const setInitialPayload = useCallback(() => {
    const initialPayload = {
      ...omit(enrichedPrescriptionPayload, ['prescriptions']),
      patient: {
        ...patient,
        dob: patient?.dob && new Date(patient.dob),
      },
      prescription: {
        ...prescription,
        transferDetails: {
          ...transferDetails,
          writtenDate: transferDetails?.writtenDate && new Date(transferDetails.writtenDate),
        },
        expirationDate: prescription?.expirationDate && new Date(prescription.expirationDate),
        needsByDate: prescription?.needsByDate && new Date(prescription.needsByDate),
      },
    };

    setInitialRequestPayload(initialPayload);
  }, [enrichedPrescriptionPayload, patient, prescription, transferDetails]);

  useEffect(() => {
    if (!selectedPartnerId) return;
    if (partner?.partnerPractices) {
      setPractices(partner.partnerPractices);
    } else {
      setPracticeLoading(true);
      dispatch(getPartnerPractices({ partnerId: selectedPartnerId })).finally(() =>
        setPracticeLoading(false)
      );
    }
  }, [selectedPartnerId, partner, dispatch]);

  useEffect(() => {
    setInitialPayload();
  }, [setInitialPayload, scriptId]);

  const handleUpdateEnrichedPayload = (data) => handleImportEnrichedRequest(data);

  return (
    <Dialog maxWidth='md' fullWidth open={showModal}>
      <DialogTitle variant='h5' sx={{ m: 2 }}>
        {`Edit Script Request - ${scriptId}`}
      </DialogTitle>
      {initialRequestPayload ? (
        <Grid container sx={{ pl: 2 }}>
          <Form
            onSubmit={handleUpdateEnrichedPayload}
            initialValues={initialRequestPayload}
            render={({ handleSubmit, invalid, form, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid container sx={{ pr: 2 }}>
                    <Grid container item xs={12} columnSpacing={3}>
                      <Grid item xs={6}>
                        <SelectField
                          required
                          label='Partner'
                          name='partnerUuid'
                          id='RxInTakeRequestEditModal-partnerUuid-dropdown'
                          onChange={(event) => {
                            if (!isEmpty(event.target.value)) {
                              setSelectedPartnerId(event.target.value);
                              form.change('partnerUuid', event.target.value);
                            }
                          }}
                        >
                          {map(partners, ({ partnerName, partnerUuid }) => (
                            <MenuItem value={partnerUuid} key={partnerUuid}>
                              {partnerName}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container alignItems='center'>
                          <Grid item sx={{ width: practiceLoading ? '90%' : '100%' }}>
                            <SelectField
                              required
                              label='Practice'
                              name='practiceId'
                              id='RxInTakeRequestEditModal-practiceId-dropdown'
                              disabled={practiceLoading || !practices?.length}
                            >
                              {map(practices, (practice) => (
                                <MenuItem value={practice?.practiceId} key={practice?.practiceId}>
                                  {practice?.practiceName}
                                </MenuItem>
                              ))}
                            </SelectField>
                          </Grid>
                          {practiceLoading && (
                            <Grid item sx={{ ml: 2 }}>
                              <CircularProgress size={20} />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} columnSpacing={3}>
                      <Grid item xs={12}>
                        <Typography variant='h6' sx={{ fontWeight: 600, mb: 3 }}>
                          Patient Details
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='First Name'
                          name='patient.firstName'
                          id='RxInTakeRequestEditModal-firstName-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Last Name'
                          name='patient.lastName'
                          id='RxInTakeRequestEditModal-lastName-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Phone Number'
                          name='patient.phoneNumber'
                          id='RxInTakeRequestEditModal-phoneNumber-input'
                          validations={[
                            validateValueWithRegex('Phone number must be 10 digits', /^\d{10}$/),
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Email'
                          name='patient.emailAddress'
                          id='RxInTakeRequestEditModal-emailAddress-input'
                          validations={[validateEmail()]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectField
                          required
                          label='Gender'
                          name='patient.gender'
                          id='RxInTakeRequestEditModal-gender-select'
                        >
                          {map(omit(gender, 'a'), (name, value) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>
                      <Grid item xs={6}>
                        <DateField
                          required
                          label='Date of Birth'
                          name='patient.dob'
                          id='RxInTakeRequestEditModal-dob-input'
                          maxDate={new Date()}
                          validations={[validateMaxDate('Should be a valid date', new Date())]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='AddressLine 1'
                          name='patient.patientAddress.addressLine1'
                          id='RxInTakeRequestEditModal-patientAddress-addressLine1-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label='AddressLine 2'
                          name='patient.patientAddress.addressLine2'
                          id='RxInTakeRequestEditModal-patientAddress-addressLine2-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Address City'
                          name='patient.patientAddress.addressCity'
                          id='RxInTakeRequestEditModal-patientAddress-addressCity-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectField
                          required
                          label='Address State'
                          name='patient.patientAddress.addressState'
                          id='RxInTakeRequestEditModal-state-select'
                        >
                          {map(usStateAbbreviation, ({ name, abbreviation }) => (
                            <MenuItem value={abbreviation} key={abbreviation}>
                              {name}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Address Zip'
                          format={formatZipCode}
                          name='patient.patientAddress.addressZip'
                          id='RxInTakeRequestEditModal-patientAddress-addressZip-input'
                          validations={[validateZipCode()]}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} columnSpacing={3}>
                      <Grid item xs={12}>
                        <Typography variant='h6' sx={{ fontWeight: 600, mb: 3 }}>
                          Prescription Details
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Description'
                          name='prescription.description'
                          id='RxInTakeRequestEditModal-description-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Strength'
                          name='prescription.strength'
                          id='RxInTakeRequestEditModal-strength-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Total Refills'
                          name='prescription.totalRefills'
                          id='RxInTakeRequestEditModal-totalRefills-input'
                          validations={[validateMinNumber('Refills should not be less than 0', 0)]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Quantity'
                          name='prescription.quantity'
                          id='RxInTakeRequestEditModal-quantity-input'
                          validations={[validateMinNumber('Quantity should not be less than 1', 1)]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DateField
                          label='Expiration Date'
                          name='prescription.expirationDate'
                          id='RxInTakeRequestEditModal-expirationDate-input'
                          minDate={new Date()}
                          validations={[
                            validateMinDate('Should be a valid date', new Date()),
                            ...(values.prescription?.needsByDate
                              ? [
                                  validateDateConstraint(
                                    'Should be after the needs by date',
                                    'isAfter',
                                    values.prescription.needsByDate
                                  ),
                                ]
                              : []),
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DateField
                          label='Needs by Date'
                          name='prescription.needsByDate'
                          id='RxInTakeRequestEditModal-needsByDate-input'
                          minDate={new Date()}
                          validations={[
                            validateMinDate('Should be a valid date', new Date()),
                            validateMaxDate(
                              'Date should not be after maximal date',
                              new Date().setFullYear(new Date().getFullYear() + 1)
                            ),
                            ...(values.prescription?.expirationDate
                              ? [
                                  validateDateConstraint(
                                    'Should be before the expiration date',
                                    'isBefore',
                                    values.prescription.expirationDate
                                  ),
                                ]
                              : []),
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          multiline
                          minRows={5}
                          maxLength={250}
                          label='Directions'
                          name='prescription.directions'
                          id='RxInTakeRequestEditModal-directions-input'
                          validations={[validateMinLength('Minimum of 3 characters', 3)]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          multiline
                          minRows={5}
                          label='Notes'
                          name='prescription.notes'
                          id='RxInTakeRequestEditModal-notes-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <CheckboxField
                          id='RxInTakeRequestEditModal-dispenseAsWritten-checkbox'
                          label='Dispense As Written'
                          name='prescription.dispenseAsWritten'
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} columnSpacing={3}>
                      <Grid item xs={12}>
                        <Typography variant='h6' sx={{ fontWeight: 600, mb: 3 }}>
                          Transfer Details
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <DateField
                          required
                          label='Written Date'
                          name='prescription.transferDetails.writtenDate'
                          id='RxInTakeRequestEditModal-transferDetails-writtenDate'
                          maxDate={new Date()}
                          validations={[validateMaxDate('Should not be a future date', new Date())]}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            fontWeight: 600,
                            mb: 3,
                            color: lighten(theme.palette.text.primary, 0.05),
                          }}
                        >
                          To Pharmacy :
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Pharmacy Name'
                          name='prescription.transferDetails.toPharmacy.name'
                          id='RxInTakeRequestEditModal-toPharmacy-name-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label='Phone Number'
                          name='prescription.transferDetails.toPharmacy.phoneNumber'
                          id='RxInTakeRequestEditModal-toPharmacy-phoneNumber-input'
                          validations={[
                            validateValueWithRegex('Phone number must be 10 digits', /^\d{10}$/),
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='AddressLine 1'
                          name='prescription.transferDetails.toPharmacy.pharmacyAddress.addressLine1'
                          id='RxInTakeRequestEditModal-toPharmacy-addressLine1-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label='AddressLine 2'
                          name='prescription.transferDetails.toPharmacy.pharmacyAddress.addressLine2'
                          id='RxInTakeRequestEditModal-toPharmacy-addressLine2-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Address City'
                          name='prescription.transferDetails.toPharmacy.pharmacyAddress.addressCity'
                          id='RxInTakeRequestEditModal-toPharmacy-addressCity-input'
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectField
                          required
                          label='Address State'
                          name='prescription.transferDetails.toPharmacy.pharmacyAddress.addressState'
                          id='RxInTakeRequestEditModal-toPharmacy-addressState-input'
                        >
                          {map(usStateAbbreviation, ({ name, abbreviation }) => (
                            <MenuItem value={abbreviation} key={abbreviation}>
                              {name}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          required
                          format={formatZipCode}
                          label='Address Zip'
                          name='prescription.transferDetails.toPharmacy.pharmacyAddress.addressZip'
                          id='RxInTakeRequestEditModal-patientAddress-addressZip-input'
                          validations={[validateZipCode()]}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            fontWeight: 600,
                            mb: 3,
                            color: lighten(theme.palette.text.primary, 0.05),
                          }}
                        >
                          Prescribed by :
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          label='NPI'
                          name='prescription.prescribedBy.npi'
                          id='RxInTakeRequestEditModal-prescribedBy-npi-input'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label='DEA'
                          name='prescription.prescribedBy.dea'
                          id='RxInTakeRequestEditModal-prescribedBy-dea-input'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label='Title'
                          name='prescription.prescribedBy.title'
                          id='RxInTakeRequestEditModal-prescribedBy-title-input'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          label='First Name'
                          name='prescription.prescribedBy.firstName'
                          id='RxInTakeRequestEditModal-prescribedBy-firstName-input'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Last Name'
                          name='prescription.prescribedBy.lastName'
                          id='RxInTakeRequestEditModal-prescribedBy-lastName-input'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label='Middle Name'
                          name='prescription.prescribedBy.middleName'
                          id='RxInTakeRequestEditModal-prescribedBy-middleName-input'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Phone Number'
                          name='prescription.prescribedBy.phoneNumber'
                          id='RxInTakeRequestEditModal-prescribedBy-middleName-input'
                          validations={[
                            validateValueWithRegex('Phone number must be 10 digits', /^\d{10}$/),
                          ]}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Address Line 1'
                          name='prescription.prescribedBy.address.addressLine1'
                          id='RxInTakeRequestEditModal-prescribedBy-addressLine1-input'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          label='Address Line 2'
                          name='prescription.prescribedBy.address.addressLine2'
                          id='RxInTakeRequestEditModal-prescribedBy-addressLine2-input'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          label='Address City'
                          name='prescription.prescribedBy.address.addressCity'
                          id='RxInTakeRequestEditModal-prescribedBy-addressCity-input'
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <SelectField
                          required
                          label='Address State'
                          name='prescription.prescribedBy.address.addressState'
                          id='RxInTakeRequestEditModal-prescribedBy-addressState-input'
                        >
                          {map(usStateAbbreviation, ({ name, abbreviation }) => (
                            <MenuItem value={abbreviation} key={abbreviation}>
                              {name}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          required
                          format={formatZipCode}
                          label='Address Zip'
                          name='prescription.prescribedBy.address.addressZip'
                          id='RxInTakeRequestEditModal-patientAddress-addressZip-input'
                          validations={[validateZipCode()]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions sx={{ p: 3 }}>
                  <Button variant='outlined' color='secondary' onClick={onClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={loading}
                    disabled={invalid}
                    variant='contained'
                    type='submit'
                  >
                    Import
                  </LoadingButton>
                </DialogActions>
              </form>
            )}
          />
        </Grid>
      ) : (
        <LoadingModule />
      )}
    </Dialog>
  );
};

RxInTakeRequestEditModal.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  scriptId: PropTypes.string.isRequired,
  handleImportEnrichedRequest: PropTypes.func,
};

RxInTakeRequestEditModal.defaultProps = {
  loading: false,
  handleImportEnrichedRequest: undefined,
};

export default RxInTakeRequestEditModal;
