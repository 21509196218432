import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { includes, isEmpty, isNil, map, toLower } from 'lodash';
import { visuallyHidden } from '@mui/utils';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { listOrdersByPage, sortOrders } from 'store/thunks/workQueueThunks';
import OrderVerificationRow from './OrderVerificationRow';

const headCells = [
  {
    id: 'orderId',
    label: 'Order Id',
  },
  {
    id: 'needsByDate',
    label: 'Needs By Date',
  },
  {
    id: 'PatientName',
    label: 'Patient Name',
  },
  {
    id: 'orderStatus',
    label: 'Status',
  },
  {
    id: 'createdAt',
    label: 'Created At',
  },
  {
    id: 'lastUpdated',
    label: 'Updated At',
  },
  {
    id: 'waitingOnFill',
    label: 'Waiting On Fill',
  },
  {
    id: 'totalInOrder',
    label: 'Total In Order',
  },
];

const sortableFields = [
  'needsByDate',
  'waitingOnFill',
  'orderId',
  'patientName',
  'createdAt',
  'lastUpdated',
  'totalInOrder',
];

const OrderVerificationTable = () => {
  const dispatch = useDispatch();

  const { sortBy, pages, currentPage } = useSelector(
    ({ workQueue }) => workQueue.orderVerification
  );

  const orderVerification = pages[currentPage];

  const totalPages = useSelector(({ workQueue }) =>
    Math.ceil(workQueue.orderVerification.count / 25)
  );

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      sortOrders({
        sortBy: {
          sortKey,
          sortOrder: sortBy.sortKey === sortKey && sortBy.sortOrder === 'ASC' ? 'DESC' : 'ASC',
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return;
    }

    dispatch(listOrdersByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(listOrdersByPage({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align='center'>
                    {!includes(sortableFields, headCell.id) ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={sortBy.sortKey === headCell.id}
                        direction={
                          sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {map(orderVerification, (order) => (
                <OrderVerificationRow key={order.orderId} order={order} />
              ))}
            </TableBody>

            {!isNil(orderVerification) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(orderVerification) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(orderVerification) && isEmpty(orderVerification) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default OrderVerificationTable;
