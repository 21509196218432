/* eslint-disable react/jsx-props-no-spreading */
import { React, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { LoadingButton } from '@mui/lab';
import { isEmpty, pick, reduce, values } from 'lodash';

import { getFulfillingPharmacies } from 'store/thunks/pharmacyThunks';
import PartnerProfileForm from './PartnerProfileForm';

const ManagePartnerProfileModal = ({
  loading,
  showModel,
  handleCloseModal,
  managePartnerProfile,
}) => {
  const dispatch = useDispatch();
  const [fulfillingPharmacyLoading, setFulfillingPharmacyLoading] = useState(false);
  const { currentTenantId } = useSelector(({ auth }) => auth);

  const listFulfillingPharmacies = (fulFillingPharmacies) => {
    return reduce(
      fulFillingPharmacies,
      (res, { name, fulfillingPharmacyId }) => {
        res[name] = fulfillingPharmacyId;
        return res;
      },
      {}
    );
  };

  const fulfillingPharmacyNames = useSelector(({ pharmacy }) =>
    listFulfillingPharmacies(pharmacy[currentTenantId])
  );

  const handlePartnerProfile = (formData) => {
    const { fulfillingPharmacyIds, ...rest } = formData;

    managePartnerProfile({
      ...rest,
      ...(fulfillingPharmacyIds &&
        !isEmpty(fulfillingPharmacyIds) && {
          fulfillingPharmacyIds: values(pick(fulfillingPharmacyNames, fulfillingPharmacyIds)),
        }),
    });
  };

  useEffect(() => {
    setFulfillingPharmacyLoading(true);
    dispatch(getFulfillingPharmacies()).finally(() => setFulfillingPharmacyLoading(false));
  }, [dispatch]);

  return (
    <Dialog
      open={showModel}
      onClose={handleCloseModal}
      aria-describedby='Create-Partner-Profile'
      fullWidth
    >
      <>
        <DialogTitle id='Create-Partner-Profile-header'>Create Partner Profile</DialogTitle>
        <Form
          onSubmit={handlePartnerProfile}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <PartnerProfileForm
                  fulfillingPharmacyNames={Object.keys(fulfillingPharmacyNames) || []}
                  fulfillingPharmacyLoading={fulfillingPharmacyLoading}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  type='submit'
                  disabled={invalid}
                >
                  Submit
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </>
    </Dialog>
  );
};

export default ManagePartnerProfileModal;

ManagePartnerProfileModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  showModel: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  managePartnerProfile: PropTypes.func.isRequired,
};
