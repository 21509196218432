export default {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const awaitingRequestStatus = {
  AWAITING_EXAM: 'Awaiting Exam',
  AWAITING_PRESCRIPTION: 'Awaiting Prescription',
  CAPTURE_FAILED: 'Payment Capture Failed',
};

export const rxIntakeRequestStatus = {
  ALL: 'All',
  HOLD: 'Hold',
  OPEN: 'Open',
  CLOSED: 'Closed',
  IMPORTED: 'Imported',
  IMPORT_FAILED: 'Import Failed',
};
