/* eslint-disable import/prefer-default-export */
import { debounce } from 'lodash';
import {
  addPartnerWithApiAccessApi,
  getPartnerPracticesApi,
  getPartnerWorkflowConfigApi,
  getPartnersApi,
  listPartnersApi,
  savePartnerWorkflowConfigApi,
} from 'api/partner';
import { partnerActions } from 'store/slices/partnerSlice';

import { handleError } from './errorHandlerThunks';

export const getPartners = () => (dispatch, getState) => {
  const { currentTenantId } = getState().auth;

  return getPartnersApi()
    .then((partners) => {
      dispatch(partnerActions.loadPartners({ partners, currentTenantId }));
    })
    .catch((error) => dispatch(handleError({ error })));
};

export const getPartnerPractices =
  ({ partnerId }) =>
  (dispatch, getState) => {
    const { currentTenantId } = getState().auth;

    return getPartnerPracticesApi(partnerId)
      .then((practices) => {
        dispatch(
          partnerActions.loadPartnerPractices({
            currentTenantId,
            practices,
            partnerId,
          })
        );
      })
      .catch((error) => {
        dispatch(handleError({ error }));
      });
  };

export const listPartnerProfiles =
  ({ page }) =>
  (dispatch, getState) => {
    const { sortBy, filter } = getState().partner.partnerManagement;
    dispatch(partnerActions.updateCurrentPage({ page }));

    return listPartnersApi({ page, sortBy, filter })
      .then(({ count, result }) => {
        dispatch(partnerActions.listPartnerProfiles({ page, items: result, count }));
      })
      .catch((error) => {
        dispatch(handleError({ error }));
      });
  };

export const debouncedListPartnerProfiles = debounce(({ dispatch }) => {
  return dispatch(listPartnerProfiles({ page: 1 }));
}, 1000);

export const updatePartnerProfileSortBy =
  ({ sortBy }) =>
  (dispatch) => {
    dispatch(partnerActions.updateSortBy({ sortBy }));
    debouncedListPartnerProfiles({ dispatch });
  };

export const updatePartnerProfileFilter =
  ({ filter }) =>
  (dispatch) => {
    dispatch(partnerActions.updateFilter({ filter }));
    debouncedListPartnerProfiles({ dispatch });
  };

export const getPartnerWorkflowConfig = (partnerUUID) => (dispatch) => {
  return getPartnerWorkflowConfigApi(partnerUUID)
    .then((workflowConfig) => workflowConfig)
    .catch((error) => dispatch(handleError({ error })));
};

export const savePartnerWorkflowConfig =
  ({ partnerUUID, input }) =>
  (dispatch) =>
    savePartnerWorkflowConfigApi({ partnerUUID, input })
      .then((workflowConfig) => !!workflowConfig?.partnerWorkflowConfigId)
      .catch((error) => dispatch(handleError({ error })));

export const addPartnerWithApiAccess = (input) => (dispatch, getState) => {
  const { filter } = getState().partner.partnerManagement;

  return addPartnerWithApiAccessApi(input)
    .then((response) => {
      dispatch(updatePartnerProfileFilter({ filter }));
      return response;
    })
    .catch((error) => dispatch(handleError({ error })));
};
