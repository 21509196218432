export const VerificationStatusInfo = {
  VERIFIED: 'Verified',
  UNVERIFIED: 'Pending Verification',
  FAILED: 'Verification Failed',
};

export const VerificationStatus = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  FAILED: 'FAILED',
};
