/* eslint-disable import/prefer-default-export */
import { isNull, reduce } from 'lodash';

import graphqlClient from '../graphql';
import { getRequestByReferenceIdGql, listApiOrdersStatusGql } from './gql';
import { transformListApiOrdersStatus, transformPomRequestDetails } from './transform';

export const listApiOrdersStatusApi = ({ page, sortBy, filter }) =>
  graphqlClient
    .request(listApiOrdersStatusGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        filter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListApiOrdersStatus);

export const getRequestByReferenceIdApi = ({ referenceId, requestId }) =>
  graphqlClient
    .request(getRequestByReferenceIdGql, { referenceId, requestId })
    .then(transformPomRequestDetails);
