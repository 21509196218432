import { React, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';

import SelectField from 'common/forms/SelectField';
import TextField from 'common/forms/TextField';
import { validateMaxLength, validateMinLength } from 'common/forms/formValidations';
import { updatePartnerProfileFilter } from 'store/thunks/partnerThunks';

import { PartnerProfileApiAccess, PartnerProfileFieldKeys } from 'enums/partner.enum';

const PartnerFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { filter } = useSelector(({ partner }) => partner.partnerManagement);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'PartnerFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);
    dispatch(updatePartnerProfileFilter({ filter: formData }));
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>
      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Partner Name'
                    name={PartnerProfileFieldKeys.PARTNER_NAME}
                    id='PartnerFilterButton-partnerName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Partner Short Code'
                    name='partnerShortCode'
                    id='PartnerFilterButton-partnerShortCode-input'
                    validations={[
                      validateMinLength('Minimum of 2 characters', 2),
                      validateMaxLength('Maximum of 5 characters', 5),
                    ]}
                  />

                  <SelectField
                    label='Api Access'
                    name={PartnerProfileFieldKeys.HAS_API_ACCESS}
                    id='PartnerFilterButton-status-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    <MenuItem value={PartnerProfileApiAccess.HAS_API_ACCESS}>Yes</MenuItem>
                    <MenuItem value={PartnerProfileApiAccess.NO_API_ACCESS}>No</MenuItem>
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default PartnerFilterButton;
