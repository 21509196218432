/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { find, isBoolean, isEmpty, map, omit } from 'lodash';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { formatZipCode } from 'common/utils';
import RadioGroupField from 'common/forms/RadioGroupField';
import { validateZipCode } from 'common/forms/formValidations';
import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import CheckboxField from 'common/forms/CheckboxField';
import { patientActions } from 'store/slices/patientSlice';
import { updateOrderAddress } from 'store/thunks/patientThunks';

import useSmsResetWarningDialog from '../ShippingTab/useSmsResetWarningDialog';

const EditOrderAddressButton = ({ orderId, mpi, orderAddress, afterAddressUpdate, disabled }) => {
  const dispatch = useDispatch();

  const patientInfo = useSelector(({ patient }) => patient[mpi]);
  const notificationPreference = patientInfo?.notificationPreferences;

  const { InfoDialog, showWarning } = useSmsResetWarningDialog();
  const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  const [inputAddress, setInputAddress] = useState({ ...orderAddress });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const openDialog = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setInputAddress(orderAddress);
      setSuggestedAddresses([]);
      setShowModal(false);
    }
  };

  const closeDialog = () => {
    setLoading(false);
    setSuggestedAddresses([]);
    setShowModal(true);
  };

  const resetPhonePreference = () => {
    dispatch(
      patientActions.updatePatientNotificationPreference({
        phone: false,
        email: notificationPreference?.email,
        mpi,
      })
    );
  };

  const dispatchUpdateOrderAddress = (formData) => {
    setInputAddress(formData);
    setLoading(true);

    const addressInput = {
      ...omit(formData, 'line1', 'line2'),
      addressLine1: formData.line1,
      addressLine2: formData.line2,
    };

    return dispatch(
      updateOrderAddress({
        mpi,
        orderId,
        addressInput,
      })
    ).then((response) => {
      setLoading(false);

      if (!isBoolean(response)) {
        setSuggestedAddresses(response);
      } else if (response) {
        enqueueSnackbar({ message: 'Order Address Updated Successfully!', variant: 'success' });
        afterAddressUpdate();
        closeDialog();
        handleCloseModal();
      }
    });
  };

  const handleEditOrderAddress = (formData) => {
    const isDisallowedState = !find(usStateAbbreviation, {
      abbreviation: formData.state,
    })?.isSMSAllowed;

    showWarning({
      warn: formData.isDefault && isDisallowedState && notificationPreference?.phone,
      callback: () => dispatchUpdateOrderAddress(formData),
      warnCallback: () => {
        resetPhonePreference();
        return dispatchUpdateOrderAddress(formData);
      },
    });
  };

  const handleConfirmAddress = (formData) => {
    const selectedAddress = suggestedAddresses[formData.addressChoice];
    const addressToSave = {
      ...selectedAddress,
      nameOnAddress: inputAddress.nameOnAddress,
      isDefault: inputAddress.isDefault,
    };

    return dispatchUpdateOrderAddress(addressToSave);
  };

  return (
    <>
      <Button disabled={disabled} variant='outlined' color='secondary' onClick={openDialog}>
        Edit Address
      </Button>

      <InfoDialog />

      <Dialog
        open={showModal}
        aria-describedby='ActionItemTab-EditOrderAddressButton-header'
        fullWidth
      >
        <DialogTitle id='ActionItemTab-EditOrderAddressButton-header' sx={{ pb: 0 }}>
          Edit Address
        </DialogTitle>

        {!isEmpty(suggestedAddresses) ? (
          <Form
            onSubmit={handleConfirmAddress}
            initialValues={{ addressChoice: '0' }}
            render={({ handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <Typography variant='h6' sx={{ p: 0, pb: 1 }}>
                    We noticed that the address you entered may not be valid. Please select one from
                    the suggestions below.
                  </Typography>

                  <RadioGroupField
                    name='addressChoice'
                    id='ActionItemTab-EditOrderAddressButton-selectAddress-input'
                    label=''
                  >
                    <Stack direction='column' alignItems='flex-start'>
                      {map(suggestedAddresses, (address, index) => (
                        <FormControlLabel
                          key={`validateAddress-${index}`}
                          value={index}
                          control={<Radio sx={{ mr: 1 }} />}
                          label={
                            <ListItemText
                              primary={
                                <>
                                  <Typography variant='h6'>{address.line1}</Typography>
                                  {address?.line2 && <Typography>{address.line2}</Typography>}
                                  <Typography>
                                    {address.city} , {address.state}, {address.zip}
                                  </Typography>
                                </>
                              }
                            />
                          }
                        />
                      ))}
                    </Stack>
                  </RadioGroupField>
                </DialogContent>
                <DialogActions>
                  <Button variant='outlined' color='secondary' onClick={closeDialog}>
                    Cancel
                  </Button>
                  <LoadingButton loading={loading} variant='contained' type='submit'>
                    Confirm
                  </LoadingButton>
                </DialogActions>
              </form>
            )}
          />
        ) : (
          <Form
            onSubmit={handleEditOrderAddress}
            initialValues={inputAddress}
            render={({ handleSubmit, invalid }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    id='ActionItemTab-EditOrderAddressButton-nameOnAddress-input'
                    label='Name On Address'
                    name='nameOnAddress'
                    required
                    autoFocus
                  />
                  <TextField
                    id='ActionItemTab-EditOrderAddressButton-addressLine1-input'
                    label='Address Line 1'
                    name='line1'
                    required
                  />
                  <TextField
                    id='ActionItemTab-EditOrderAddressButton-addressLine2-input'
                    label='Address Line 2'
                    name='line2'
                  />
                  <TextField
                    id='ActionItemTab-EditOrderAddressButton-city-input'
                    label='City'
                    name='city'
                    required
                  />

                  <SelectField
                    id='ActionItemTab-EditOrderAddressButton-state-dropdown'
                    label='State'
                    name='state'
                    required
                  >
                    {map(usStateAbbreviation, ({ name, abbreviation }) => (
                      <MenuItem value={abbreviation} key={abbreviation}>
                        {name}
                      </MenuItem>
                    ))}
                  </SelectField>
                  <TextField
                    id='ActionItemTab-EditOrderAddressButton-zip-input'
                    label='Zip'
                    name='zip'
                    type='zip'
                    required
                    maxLength={10}
                    format={formatZipCode}
                    validations={[validateZipCode()]}
                  />
                  <CheckboxField
                    label='Default'
                    name='isDefault'
                    id='ActionItemTab-EditOrderAddressButton-isDefault-checkbox'
                  />
                </DialogContent>

                <DialogActions>
                  <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={loading}
                    variant='contained'
                    disabled={invalid}
                    type='submit'
                  >
                    Save
                  </LoadingButton>
                </DialogActions>
              </form>
            )}
          />
        )}
      </Dialog>
    </>
  );
};

EditOrderAddressButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  orderAddress: PropTypes.shape({
    line1: PropTypes.string.isRequired,
    line2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
  afterAddressUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default EditOrderAddressButton;
