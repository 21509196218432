import { useState } from 'react';
import { Button, Grid, Popover, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import TextField from 'common/forms/TextField';
import { SortKey } from 'enums/common.enum';
import { validateMinLength } from 'common/forms/formValidations';
import { updateRxIntakeRequestFilter } from 'store/thunks/workQueueThunks';

const RxInTakeRequestsFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const filter = useSelector(({ workQueue: { rxIntakeRequests } }) => rxIntakeRequests.filter);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'WorkQueue-ReviewInClaimEligibilityFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      updateRxIntakeRequestFilter({
        filter: {
          ...formData,
          requestedDate: formData.requestedDate && formData.requestedDate.toISOString(),
          needsByDate: formData.needsByDate && formData.needsByDate.toISOString(),
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Patient Name'
                    name={SortKey.PATIENT_NAME}
                    id='WorkQueue-RxInTakeRequestsFilterButton-patientName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Medication Name'
                    name={SortKey.MEDICATION_NAME}
                    id='WorkQueue-RxInTakeRequestsFilterButton-mediationName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Practice'
                    name={SortKey.PRACTICE_NAME}
                    id='WorkQueue-RxInTakeRequestsFilterButton-practiceName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Docutrack Id'
                    name={SortKey.SCRIPT_ID}
                    id='WorkQueue-RxInTakeRequestsFilterButton-scriptId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default RxInTakeRequestsFilterButton;
