/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const getFulfillingPharmaciesGql = gql`
  query getFulfillingPharmacies($pharmacyId: UUID!) {
    getFulfillingPharmacies(pharmacyId: $pharmacyId) {
      city
      fulfillingPharmacyId
      line1
      line2
      name
      npi
      pharmacist
      phone
      state
      zip
    }
  }
`;

export const transferOrderToFacilityGql = gql`
  mutation transferOrderFulfillingPharmacy($orderId: String!, $toFacilityId: UUID!) {
    transferOrderFulfillingPharmacy(orderId: $orderId, fulfillingPharmacyId: $toFacilityId)
  }
`;
