/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import {
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Fragment, forwardRef, useEffect, useMemo, useState } from 'react';
import { filter, find, includes, isNil, map, reduce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { lighten, useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns';

import { loadRequestDetails } from 'store/thunks/orderStatusThunks';
import LoadingModule from 'common/components/LoadingModule';
import gender from 'common/constants/gender';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import VerifiedTag from 'common/modules/VerifiedTag';
import { VerificationStatus } from 'enums/provider.enum';

import EScriptOrdersProgressTab from './EScriptOrdersProgressTab';

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const EScriptRequestView = ({ referenceId, requestId, open, onClose }) => {
  const dispatch = useDispatch();
  const { pages, currentPage } = useSelector(({ orderStatus: { eScriptOrders } }) => eScriptOrders);
  const [viewMoreElements, setViewMoreElements] = useState([]);
  const [openStates, setOpenStates] = useState({});
  const requests = pages[currentPage] || [];
  const requestDetails = find(requests, { requestId })?.requestDetails;

  const patient = requestDetails?.patient;
  const prescription = requestDetails?.prescription;
  const originatingPharmacy = requestDetails?.prescription?.originatingPharmacy;
  const transferPharmacies = requestDetails?.prescription?.transferPharmacies;
  const prescribedBy = requestDetails?.prescription?.prescribedBy;
  const practice = requestDetails?.practice;
  const events = requestDetails?.events || [];

  const fieldToggleHandler = useMemo(() => {
    const pharmacyDetails = reduce(
      transferPharmacies,
      (acc, pharmacy, index) => {
        const key = `Pharmacy${pharmacy.npi}-${index}`;
        acc[key] = key;

        return acc;
      },
      {}
    );

    return {
      Patient: 'Patient',
      Prescription: 'Prescription',
      Pharmacy: 'Pharmacy',
      Prescriber: 'Prescriber',
      ...pharmacyDetails,
    };
  }, [transferPharmacies]);

  const {
    isOpenPatient,
    isOpenPrescription,
    isOpenPharmacy,
    isOpenPrescriber,
    ...dynamicOpenStates
  } = openStates;

  const upsertViewMoreElements = (element) => {
    if (includes(viewMoreElements, element)) {
      const updatedElements = filter(
        viewMoreElements,
        (currentElement) => element !== currentElement
      );

      return setViewMoreElements([...updatedElements]);
    }

    viewMoreElements.push(element);
    return setViewMoreElements([...viewMoreElements]);
  };

  useEffect(() => {
    setOpenStates(
      Object.keys(fieldToggleHandler).reduce((acc, key) => {
        acc[`isOpen${key}`] = includes(viewMoreElements, fieldToggleHandler[key]);
        return acc;
      }, {})
    );
  }, [viewMoreElements, fieldToggleHandler]);

  const eventData = find(
    events,
    ({ actionItemType }) => actionItemType === 'ORDER_CREATED_IN_DYNAMO'
  )?.interactionData;

  const shippingAddress = eventData?.originalEventPayload?.fulfillmentDetails?.shippingAddress;

  useEffect(() => {
    dispatch(loadRequestDetails({ referenceId, requestId }));
  }, [dispatch, referenceId, requestId]);

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <Grid
        container
        sx={{ px: 3, py: 1, bgcolor: 'primary.light' }}
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography component='h2' variant='h6' fontWeight='bold'>
          Request - {referenceId}
        </Typography>

        <IconButton onClick={onClose} aria-label='Close Dialog'>
          <CloseIcon />
        </IconButton>
      </Grid>

      <DialogContent>
        {isNil(requestDetails) ? (
          <LoadingModule height='25vh' />
        ) : (
          <Grid container direction='row' sx={{ px: 4 }}>
            <Grid item md={6}>
              <Grid sx={{ pb: 2 }}>
                <Grid container direction='row' alignItems='center'>
                  <Typography variant='h6' fontWeight='bold'>
                    Patient
                  </Typography>

                  <IconButton
                    aria-label='Toggle Details patient'
                    onClick={() => upsertViewMoreElements(fieldToggleHandler.Patient)}
                  >
                    {isOpenPatient ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Grid>

                <ItemLabelValue label='MPI' value={patient?.mpi} />
                <ItemLabelValue
                  label='Name'
                  value={`${patient?.lastName}, ${patient?.firstName}`}
                />
                <ItemLabelValue label='External Id' value={patient?.externalPatientId} />
                <Collapse in={isOpenPatient} timeout='auto' unmountOnExit>
                  <ItemLabelValue label='DOB' value={patient?.dob} />
                  <ItemLabelValue label='Gender' value={gender[patient?.gender]} />
                  <ItemLabelValue label='Email' value={patient?.email} />
                  <ItemLabelValue label='Phone' value={patient?.mobilePhone} />
                </Collapse>
              </Grid>

              <Grid sx={{ pb: 2, pt: 2 }}>
                <Grid container direction='row' alignItems='center'>
                  <Typography variant='h6' fontWeight='bold'>
                    Prescription
                  </Typography>

                  <IconButton
                    aria-label='Toggle Details prescription'
                    onClick={() => upsertViewMoreElements(fieldToggleHandler.Prescription)}
                  >
                    {isOpenPrescription ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Grid>

                <ItemLabelValue label='Description' value={prescription?.description} />
                <ItemLabelValue label='Strength' value={prescription?.strength} />
                <Collapse in={isOpenPrescription} timeout='auto' unmountOnExit>
                  <ItemLabelValue label='Expiration Date' value={prescription?.expirationDate} />
                  <ItemLabelValue label='DAW' value={prescription?.dispenseAsWritten} />
                  <ItemLabelValue label='Directions' value={prescription?.directions} />
                  <ItemLabelValue label='Quantity' value={prescription?.quantity} />
                  <ItemLabelValue label='Refills' value={prescription?.totalRefills} />
                  <ItemLabelValue label='Needs By Date' value={prescription?.needsByDate} />
                  <ItemLabelValue label='Notes' value={prescription?.notes} />
                </Collapse>
              </Grid>

              <Grid sx={{ pb: 2, pt: 2 }}>
                <Typography variant='h6' fontWeight='bold'>
                  Practice
                </Typography>

                <Grid sx={{ pt: 1 }}>
                  <ItemLabelValue label='Practice Id' value={practice?.practiceId} />
                  <ItemLabelValue label='Name' value={practice?.practiceName} />
                </Grid>
              </Grid>

              <Grid sx={{ pb: 2, pt: 2 }}>
                <Typography variant='h6' fontWeight='bold'>
                  Fulfillment - Shipping Address
                </Typography>

                <Grid sx={{ pt: 1 }}>
                  <ItemLabelValue label='Line 1' value={shippingAddress?.addressLine1} />
                  <ItemLabelValue label='Line 2' value={shippingAddress?.addressLine2} />
                  <ItemLabelValue label='City' value={shippingAddress?.addressCity} />
                  <ItemLabelValue
                    label='State'
                    value={
                      find(usStateAbbreviation, { abbreviation: shippingAddress?.addressState })
                        ?.name || shippingAddress?.addressState
                    }
                  />
                  <ItemLabelValue label='Zip' value={shippingAddress?.addressZip} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6}>
              <Typography variant='h6' fontWeight='bold'>
                Transfer Details
              </Typography>

              <Grid container direction='row' alignItems='center' sx={{ pb: 1 }}>
                <Typography variant='h6' fontWeight='bold'>
                  Originating Pharmacy
                </Typography>

                <IconButton
                  aria-label='Toggle Details pharmacy'
                  onClick={() => upsertViewMoreElements(fieldToggleHandler.Pharmacy)}
                >
                  {isOpenPharmacy ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>

              <ItemLabelValue label='Name' value={originatingPharmacy?.name} />
              <ItemLabelValue label='Pharmacist' value={originatingPharmacy?.pharmacistName} />
              <Collapse in={isOpenPharmacy} timeout='auto' unmountOnExit>
                <ItemLabelValue label='DEA' value={originatingPharmacy?.dea} />
                <ItemLabelValue label='NPI' value={originatingPharmacy?.npi} />
                <ItemLabelValue label='Phone' value={originatingPharmacy?.phoneNumber} />
                <ItemLabelValue
                  label='Line 1'
                  value={originatingPharmacy?.pharmacyAddress?.addressLine1}
                />
                <ItemLabelValue
                  label='Line 2'
                  value={originatingPharmacy?.pharmacyAddress?.addressLine2}
                />
                <ItemLabelValue
                  label='City'
                  value={originatingPharmacy?.pharmacyAddress?.addressCity}
                />
                <ItemLabelValue
                  label='State'
                  value={originatingPharmacy?.pharmacyAddress?.addressState}
                />
                <ItemLabelValue
                  label='Zip'
                  value={originatingPharmacy?.pharmacyAddress?.addressZip}
                />
              </Collapse>

              {map(transferPharmacies, (transferPharmacy, index) => {
                const pharmacyKey = `Pharmacy${transferPharmacy.npi}-${index}`;
                const isOpenDynamic = dynamicOpenStates[`isOpen${pharmacyKey}`];

                return (
                  <Fragment key={pharmacyKey}>
                    <Grid container direction='row' alignItems='center' sx={{ pb: 1 }}>
                      <Typography variant='h6' fontWeight='bold'>
                        Transfer Pharmacy
                      </Typography>

                      <IconButton
                        aria-label='Toggle Details Transfer pharmacy'
                        onClick={() => upsertViewMoreElements(pharmacyKey)}
                      >
                        {isOpenDynamic ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </Grid>

                    <ItemLabelValue label='Name' value={transferPharmacy?.name} />
                    <ItemLabelValue label='Pharmacist' value={transferPharmacy?.pharmacistName} />
                    <Collapse in={isOpenDynamic} timeout='auto' unmountOnExit>
                      <ItemLabelValue label='DEA' value={transferPharmacy?.dea} />
                      <ItemLabelValue label='NPI' value={transferPharmacy?.npi} />
                      <ItemLabelValue
                        label='Transfer Date'
                        value={
                          transferPharmacy?.transferDate
                            ? format(new Date(transferPharmacy.transferDate), 'MM/dd/yyyy hh:mmaaa')
                            : undefined
                        }
                      />
                      <ItemLabelValue label='Phone' value={transferPharmacy?.phoneNumber} />
                      <ItemLabelValue
                        label='Line 1'
                        value={transferPharmacy?.pharmacyAddress?.addressLine1}
                      />
                      <ItemLabelValue
                        label='Line 2'
                        value={transferPharmacy?.pharmacyAddress?.addressLine2}
                      />
                      <ItemLabelValue
                        label='City'
                        value={transferPharmacy?.pharmacyAddress?.addressCity}
                      />
                      <ItemLabelValue
                        label='State'
                        value={transferPharmacy?.pharmacyAddress?.addressState}
                      />
                      <ItemLabelValue
                        label='Zip'
                        value={transferPharmacy?.pharmacyAddress?.addressZip}
                      />
                    </Collapse>
                  </Fragment>
                );
              })}

              <Grid container direction='row' alignItems='center'>
                <Typography variant='h6' fontWeight='bold' sx={{ pb: 1, pt: 3 }}>
                  Prescribed By
                </Typography>

                <IconButton
                  sx={{ mt: 2 }}
                  aria-label='Toggle Details prescriber'
                  onClick={() => upsertViewMoreElements(fieldToggleHandler.Prescriber)}
                >
                  {isOpenPrescriber ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>

              <ItemLabelValue label='Title' value={prescribedBy?.title} />
              <ItemLabelValue label='Name' value={prescribedBy?.name} />
              <Collapse in={isOpenPrescriber} timeout='auto' unmountOnExit>
                <ItemLabelValue label='DEA' value={prescribedBy?.dea} />
                <ItemLabelValue
                  label='NPI'
                  value={prescribedBy?.npi}
                  verificationTag={
                    prescribedBy?.verificationStatus || VerificationStatus.UNVERIFIED
                  }
                />
                <ItemLabelValue label='Phone' value={prescribedBy?.phoneNumber} />
                <ItemLabelValue label='Line 1' value={prescribedBy?.address?.addressLine1} />
                <ItemLabelValue label='Line 2' value={prescribedBy?.address?.addressLine2} />
                <ItemLabelValue label='City' value={prescribedBy?.address?.addressCity} />
                <ItemLabelValue label='State' value={prescribedBy?.address?.addressState} />
                <ItemLabelValue label='Zip' value={prescribedBy?.address?.addressZip} />
              </Collapse>
            </Grid>

            <EScriptOrdersProgressTab
              initialTab='events'
              requestId={requestId}
              referenceId={referenceId}
            />
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

EScriptRequestView.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  requestId: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired,
};

export default EScriptRequestView;

const ItemLabelValue = ({ value, label, verificationTag }) => {
  const theme = useTheme();

  return (
    <Grid container sx={{ pb: 1 }}>
      <Grid item sx={{ minWidth: 250 }}>
        <Typography variant='body1' sx={{ color: lighten(theme.palette.text.primary) }}>
          {label}
        </Typography>
      </Grid>
      <Grid item sx={{ minWidth: 100, display: 'flex', alignItem: 'center' }}>
        <Typography variant='body1'>{value || 'NA'}</Typography>
        {verificationTag && <VerifiedTag status={verificationTag} />}
      </Grid>
    </Grid>
  );
};

ItemLabelValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  verificationTag: PropTypes.string,
};

ItemLabelValue.defaultProps = { label: '', value: 'NA', verificationTag: undefined };
