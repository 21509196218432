import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { includes, isEmpty, isNil, map, toLower } from 'lodash';
import { format } from 'date-fns';
import { visuallyHidden } from '@mui/utils';
import CheckIcon from '@mui/icons-material/Check';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { listPartnerProfiles, updatePartnerProfileSortBy } from 'store/thunks/partnerThunks';
import { PartnerProfileFieldKeys } from 'enums/partner.enum';
import { SortOrder } from 'enums/common.enum';
import PartnerOptions from './PartnerOptions';

const headCells = [
  { id: PartnerProfileFieldKeys.PARTNER_NAME, label: 'Partner Name', align: 'left' },
  { id: PartnerProfileFieldKeys.PARTNER_SHORT_CODE, label: 'Partner Short Code', align: 'center' },
  { id: PartnerProfileFieldKeys.HAS_API_ACCESS, label: 'Api Access', align: 'center' },
  { id: PartnerProfileFieldKeys.CREATED_AT, label: 'Created At', align: 'right' },
  { id: PartnerProfileFieldKeys.UPDATED_AT, label: 'Updated At', align: 'right' },
];

const sortableFields = [
  PartnerProfileFieldKeys.PARTNER_NAME,
  PartnerProfileFieldKeys.PARTNER_SHORT_CODE,
  PartnerProfileFieldKeys.CREATED_AT,
  PartnerProfileFieldKeys.UPDATED_AT,
];

const PartnersTable = () => {
  const dispatch = useDispatch();

  const { pages, sortBy, currentPage, count } = useSelector(
    ({ partner }) => partner.partnerManagement
  );

  const partnerProfileList = pages[currentPage];
  const totalPages = Math.ceil(count / 25);

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;
    dispatch(
      updatePartnerProfileSortBy({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy.sortKey === sortKey && sortBy.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return;
    }

    dispatch(listPartnerProfiles({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(listPartnerProfiles({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid component={Paper} container direction='column' sx={{ py: 1, px: 2 }}>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.align}>
                    {!includes(sortableFields, headCell.id) ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={sortBy?.sortKey === headCell.id}
                        direction={
                          sortBy?.sortKey === headCell.id ? toLower(sortBy?.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {map(
                partnerProfileList,
                ({
                  partnerName,
                  partnerUuid,
                  partnerShortCode,
                  hasApiAccess,
                  createdAt,
                  updatedAt,
                }) => (
                  <TableRow key={partnerUuid} hover>
                    <TableCell align='left'>{partnerName}</TableCell>
                    <TableCell align='center' sx={{ pr: 5 }}>
                      {partnerShortCode}
                    </TableCell>
                    <TableCell align='center'>
                      {hasApiAccess && (
                        <>
                          <CheckIcon />
                          <Typography sx={visuallyHidden}>Yes</Typography>
                        </>
                      )}
                    </TableCell>
                    <TableCell align='right'>{format(new Date(createdAt), 'MM/dd/yyyy')}</TableCell>
                    <TableCell align='right'>{format(new Date(updatedAt), 'MM/dd/yyyy')}</TableCell>
                    <TableCell align='center'>
                      <PartnerOptions partner={{ partnerName, partnerUuid }} />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
            {!isNil(partnerProfileList) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={9} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>
      {isNil(partnerProfileList) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}
      {!isNil(partnerProfileList) && isEmpty(partnerProfileList) && (
        <Grid>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default PartnersTable;
