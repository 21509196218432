/* eslint-disable import/prefer-default-export */
import { isNull, reduce } from 'lodash';

import graphqlClient from '../graphql';
import {
  addPartnerWithApiAccessGql,
  getPartnerPracticesGql,
  getPartnerWorkflowConfigGql,
  getPartnersGql,
  listPartnerProfilesGql,
  savePartnerWorkflowConfigGql,
} from './gql';

export const getPartnersApi = () => {
  return graphqlClient.request(getPartnersGql).then(({ getPartners }) => getPartners);
};

export const getPartnerPracticesApi = (partnerId) => {
  return graphqlClient
    .request(getPartnerPracticesGql, { partnerId })
    .then(({ getPartnerPractices }) => getPartnerPractices);
};

export const listPartnersApi = ({ page, sortBy, filter }) => {
  const defaultItemsPerPage = Number(process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE);
  const defaultPageOffset = Number(process.env.REACT_APP_DEFAULT_PAGE_OFFSET);

  return graphqlClient
    .request(listPartnerProfilesGql, {
      limit: defaultItemsPerPage,
      offset: (page - 1) * defaultItemsPerPage || defaultPageOffset,
      sortBy,
      filter: reduce(
        filter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(({ listPartners }) => listPartners);
};

export const getPartnerWorkflowConfigApi = (partnerUUID) => {
  return graphqlClient
    .request(getPartnerWorkflowConfigGql, { partnerUUID })
    .then(({ getLatestPartnerWorkflowConfig }) => getLatestPartnerWorkflowConfig);
};

export const savePartnerWorkflowConfigApi = ({ partnerUUID, input }) => {
  return graphqlClient
    .request(savePartnerWorkflowConfigGql, { partnerUUID, input })
    .then(({ savePartnerWorkflowConfig }) => savePartnerWorkflowConfig);
};

export const addPartnerWithApiAccessApi = (input) => {
  return graphqlClient
    .request(addPartnerWithApiAccessGql, { input })
    .then(({ addPartnerWithApiAccess }) => addPartnerWithApiAccess);
};
