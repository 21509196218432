import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, Grid, MenuItem, Typography } from '@mui/material';
import useRoles from 'common/hooks/useRoles';
import { map, reject } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import useInfoDialog from 'common/hooks/useInfoDialog';
import SelectField from 'common/forms/SelectField';
import { transferOrderToFacility } from 'store/thunks/pharmacyThunks';

const TransferOrderButton = ({ orderId, mpi, orderFacilityId }) => {
  const dispatch = useDispatch();
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const currentTenantId = useSelector(({ auth }) => auth?.currentTenantId);
  const fulfillingPharmacies = useSelector(({ pharmacy }) => pharmacy[currentTenantId]);

  const transferFacilities = reject(fulfillingPharmacies, {
    fulfillingPharmacyId: orderFacilityId,
  });

  const hasAccess = useRoles();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleTransferInitiation = (formData) => {
    const { fulfillingPharmacy: toFacility } = formData;

    showInfoDialog({
      title: 'Confirm Transfer',
      message: `Are you sure you want to transfer this order to facility ${toFacility.name}?`,
      callback: () => {
        setIsLoading(true);
        dispatch(
          transferOrderToFacility({ mpi, orderId, toFacilityId: toFacility.fulfillingPharmacyId })
        )
          .then((isTransferred) => {
            if (isTransferred) {
              enqueueSnackbar(`Order Transferred to ${toFacility.name}`, { variant: 'success' });

              setShowModal(false);
            }
          })
          .finally(() => setIsLoading(false));
      },
      cancelable: true,
      confirmButtonText: 'Yes',
    });
  };

  return (
    <>
      <InfoDialog />

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='transferOrderHeader-header'
        fullWidth
      >
        <Grid container sx={{ px: 3, py: 2 }} justifyContent='space-between' alignItems='center'>
          <Typography variant='h6' component='h2' id='transferOrderHeader-header'>
            Transfer Order To Facility
          </Typography>
        </Grid>
        <DialogContent>
          <Form
            onSubmit={handleTransferInitiation}
            render={({ handleSubmit, invalid }) => (
              <form onSubmit={handleSubmit} noValidate>
                <SelectField
                  id='Transfer-facility-dropdown'
                  label='Select Facility To Initiate Transfer'
                  name='fulfillingPharmacy'
                  required
                >
                  {map(transferFacilities, (facility) => (
                    <MenuItem value={facility} key={facility.fulfillingPharmacyId}>
                      {facility.name}
                    </MenuItem>
                  ))}
                </SelectField>

                <Grid container justifyContent='flex-end' sx={{ mt: 5 }}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={handleCloseModal}
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant='contained'
                    type='submit'
                    disabled={invalid}
                    loading={isLoading}
                  >
                    Confirm
                  </LoadingButton>
                </Grid>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>

      <Button
        size='small'
        color='secondary'
        variant='outlined'
        onClick={handleOpenModal}
        disabled={!hasAccess.transferOrder}
        sx={{ mr: 2 }}
      >
        Transfer Facility
      </Button>
    </>
  );
};

TransferOrderButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  orderFacilityId: PropTypes.string.isRequired,
};

export default TransferOrderButton;
