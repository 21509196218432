import React, { useEffect, useState } from 'react';
import { find, map } from 'lodash';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import MultipleSelectField from 'common/forms/MultipleSelectField';
import DateRangePickerField from 'common/forms/DateRangePickerField';
import { updatePrescriptionOrdersFilter } from 'store/thunks/orderStatusThunks';
import { OrderType } from 'enums/requestStatus.enum';

const EScriptOrdersOuterFilters = () => {
  const dispatch = useDispatch();
  const [selectedPartner, setSelectedPartner] = useState('ALL');

  const partners = useSelector(
    ({ auth, partner: partnerStore }) => partnerStore[auth.currentTenantId]
  );
  const partnerNames = map(partners, (partner) => partner.partnerName);
  const filter = useSelector(({ orderStatus: { eScriptOrders } }) => eScriptOrders.filter);

  const onHandleDateChange = (dateRange) => {
    dispatch(
      updatePrescriptionOrdersFilter({
        filter: {
          ...filter,
          ...(dateRange && { createdAt: dateRange }),
          orderType: OrderType.E_SCRIPT,
        },
      })
    );
  };

  const onHandlePartnerFilter = (value) => {
    setSelectedPartner(value);
    dispatch(
      updatePrescriptionOrdersFilter({
        filter: {
          ...filter,
          partnerId:
            value === 'ALL'
              ? undefined
              : find(partners, (partner) => partner?.partnerName === value)?.partnerUuid,
        },
      })
    );
  };

  useEffect(() => {
    if (!filter?.partnerId) {
      setSelectedPartner('ALL');
    }
  }, [filter]);

  return (
    <Grid container gap={2}>
      <Grid item>
        <MultipleSelectField
          id='Partner-Multiple-Select'
          name='partner'
          options={[...partnerNames, 'ALL']}
          label='Partner'
          selectedValue={selectedPartner}
          onChange={onHandlePartnerFilter}
        />
      </Grid>

      <Grid item>
        <DateRangePickerField
          label='Date Range'
          onDateChange={onHandleDateChange}
          initialValue={filter?.createdAt}
        />
      </Grid>
    </Grid>
  );
};

export default EScriptOrdersOuterFilters;
