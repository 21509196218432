import moment from 'moment';
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, TextField, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { isEmpty, isNil, map } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';

const PatientPrescriptionNotes = ({ notes, onAddNote }) => {
  const theme = useTheme();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const addNotes = () => {
    setLoading(true);
    return onAddNote(message, (success) => {
      if (success) {
        setMessage('');
        setLoading(false);
      }
    });
  };

  return (
    <>
      <style>
        {`
        @media print {
          .addNoteInput {
            display: none;
          }
        }
  `}
      </style>
      <Typography variant='h5' sx={{ color: lighten(theme.palette.text.primary, 0.3) }}>
        Notes
      </Typography>
      <Divider />

      <Grid container direction='column' sx={{ mb: 3 }} gap={1} className='addNoteInput'>
        <Grid item>
          <Typography variant='h5' alignItems='flex-start' sx={{ mt: 2, fontWeight: 600 }}>
            Add
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            id='outlined-basic'
            fullWidth
            variant='outlined'
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Grid>
        <Grid container direction='row' justifyContent='flex-end'>
          <LoadingButton
            loading={loading}
            variant='contained'
            disabled={isEmpty(message)}
            onClick={addNotes}
          >
            Add
          </LoadingButton>
        </Grid>
      </Grid>

      <Divider />
      {!isEmpty(notes) ? (
        <>
          {map(notes, ({ noteId, noteAuthor, noteCreatedAt, noteContent }) => (
            <Fragment key={noteId}>
              <Grid
                sx={{ mt: 1 }}
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography variant='h6' sx={{ fontWeight: 600 }}>
                  {`${noteAuthor.firstName} ${noteAuthor.middleName || ''} ${
                    noteAuthor.lastName || ''
                  } `}
                </Typography>
                <Typography variant='body1'>
                  {moment(noteCreatedAt).format('hh:mma  MM/DD/YYYY')}
                </Typography>
              </Grid>
              <Typography variant='body1' sx={{ mt: 2 }}>
                {noteContent}
              </Typography>
              <Divider />
            </Fragment>
          ))}
        </>
      ) : (
        <Grid container direction='row' justifyContent='center' alignItems='center' sx={{ mt: 3 }}>
          {isNil(notes) ? (
            <CircularProgress />
          ) : (
            <Typography variant='h6' sx={{ mt: 2, textAlign: 'center' }}>
              Nothing Found...
            </Typography>
          )}
        </Grid>
      )}
    </>
  );
};

export default PatientPrescriptionNotes;

PatientPrescriptionNotes.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      noteId: PropTypes.string.isRequired,
      noteContent: PropTypes.string.isRequired,
      noteCreatedAt: PropTypes.string.isRequired,
      noteAuthor: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        middleName: PropTypes.string,
      }).isRequired,
    })
  ),
  onAddNote: PropTypes.func.isRequired,
};

PatientPrescriptionNotes.defaultProps = {
  notes: [],
};
